import React, {memo} from "react";
import {motion} from "framer-motion";
import {containerVariants, fadeInLeftVariant} from "../constants/animations";
import {FaCheckCircle, FaStar} from "react-icons/fa";

interface HeroProps {
    backgroundClassName?: string;
}

const Hero: React.FC<HeroProps> = ({
                                       backgroundClassName = "bg-gradient-to-br from-gray-50 to-blue-50 dark:from-gray-900 dark:to-blue-900",
                                   }) => {
    return (
        <div className="min-h-screen flex flex-col relative overflow-hidden">
            <div className={`absolute inset-0 ${backgroundClassName}`}/>

            {/* Subtle grid pattern overlay */}
            <div className="absolute inset-0 bg-grid-pattern opacity-5"></div>

            <section className="flex-grow px-6 pt-12 pb-16 md:pt-16 md:pb-20 relative z-10">
                <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                    className="max-w-7xl mx-auto"
                >
                    <div className="flex flex-col md:flex-row items-center justify-between gap-12">
                        <motion.div {...fadeInLeftVariant} className="max-w-xl w-full">
                            <img
                                src={`/logo192.png`}
                                alt="Fax App Logo"
                                className="h-[60px] w-auto mb-8"
                                draggable="false"
                            />

                            <motion.h1
                                initial={{opacity: 0, y: 20}}
                                animate={{opacity: 1, y: 0}}
                                transition={{duration: 0.8, delay: 0.3}}
                                className="font-epilogue font-bold text-4xl md:text-6xl mb-6 text-gray-800 dark:text-white leading-tight"
                            >
                                Send Fax from iPhone
                            </motion.h1>

                            <p className="font-epilogue text-xl md:text-2xl text-gray-600 dark:text-gray-300 mb-8 leading-relaxed">
                                Transform your document workflow with enterprise-grade mobile
                                faxing
                            </p>

                            <div className="space-y-4 mb-8">
                                {[
                                    "Secure document transmission",
                                    "Instant delivery confirmation",
                                    "Enterprise-grade encryption",
                                ].map((feature, index) => (
                                    <div key={index} className="flex items-center gap-3">
                                        <FaCheckCircle className="text-blue-600 dark:text-blue-400 w-5 h-5"/>
                                        <span className="text-gray-700 dark:text-gray-300">
                      {feature}
                    </span>
                                    </div>
                                ))}
                            </div>

                            <div className="flex justify-start mb-8">
                                <a
                                    href="https://apps.apple.com/app/id6535690934"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="transition-all hover:scale-105 hover:shadow-xl duration-300"
                                >
                                    <img
                                        src="/appstore.svg"
                                        alt="Download on the App Store"
                                        className="h-[70px]"
                                        draggable="false"
                                    />
                                </a>
                            </div>

                            <motion.div
                                initial={{opacity: 0, y: 20}}
                                animate={{opacity: 1, y: 0}}
                                transition={{delay: 0.3, duration: 0.6}}
                                className="bg-white dark:bg-gray-800 p-6 rounded-2xl shadow-lg border border-gray-100 dark:border-gray-700"
                            >
                                <div className="flex items-center gap-4">
                                    <StarRating/>
                                    <div className="flex flex-col">
                    <span className="text-gray-800 dark:text-white font-semibold">
                      4.8 out of 5
                    </span>
                                        <span className="text-gray-600 dark:text-gray-400 text-sm">
                      Based on 30,000+ reviews
                    </span>
                                    </div>
                                </div>
                            </motion.div>
                        </motion.div>

                        <motion.div
                            initial={{opacity: 0, y: 20}}
                            animate={{opacity: 1, y: 0}}
                            transition={{delay: 0.6, duration: 0.8}}
                            className="w-full max-w-sm md:max-w-md"
                        >
                            <div className="relative">
                                <div className="relative w-full overflow-hidden">
                                    <img
                                        src="/promo.png"
                                        alt="Fax App Interface"
                                        className="w-full h-auto object-contain max-w-[300px]"
                                        draggable="false"
                                    />
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </motion.div>
            </section>

            <footer
                className="py-6 bg-white/90 dark:bg-gray-900/90 backdrop-blur-md border-t border-gray-200 dark:border-gray-800">
                <p className="text-center text-gray-600 dark:text-gray-400">
                    &copy; {new Date().getFullYear()} Fax App. All rights reserved.
                </p>
            </footer>
        </div>
    );
};

const StarRating = memo(() => (
    <div className="flex">
        {[...Array(5)].map((_, i) => (
            <FaStar key={i} className="text-yellow-400 w-6 h-6"/>
        ))}
    </div>
));

export default memo(Hero);
