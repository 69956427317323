import {useEffect} from "react";
import {TrackingService} from "../services/tracking";

interface UseTrackingResult {
    shouldRedirect: boolean;
    redirectUrl: string | null;
}

export const useTracking = (): UseTrackingResult => {
    useEffect(() => {
        const handleTracking = async () => {
            const searchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(searchParams.entries());

            if (Object.keys(params).length === 0) {
                return;
            }

            try {
                const response = await TrackingService.sendTrackingRequest(params);
                console.log("Tracking request status:", response.status);
                console.log("Tracking response:", response);
            } catch (error) {
                console.error("Failed to send tracking request:", error);
            }
        };

        handleTracking();
    }, []);

    const searchParams = new URLSearchParams(window.location.search);
    // const gclid = searchParams.get("gclid");
    const redirectUrl = searchParams.get("redirect_url");

    return {
        // shouldRedirect: Boolean(gclid && redirectUrl),
        shouldRedirect: false,
        redirectUrl,
    };
};
