import React, {useEffect} from "react";
import "./App.css";
import Hero from "./components/Hero";
import {useTracking} from "./hooks/useTracking";

function App() {
    const {shouldRedirect, redirectUrl} = useTracking();

    useEffect(() => {
        if (shouldRedirect && redirectUrl) {
            window.location.href = redirectUrl;
        }
    }, [shouldRedirect, redirectUrl]);

    return (
        <div className="App">
            <Hero/>
        </div>
    );
}

export default App;
